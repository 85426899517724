<script>
import { headerMenu } from '@/CONF.js'

import { data_analysis } from '@/api/beijing28'

import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'

import Header from 'components/base/Header'

export default {
    name: 'TrendsToChart',
    components: {
        'v-chart': ECharts,
        Header,
    },
    data() {

        if (_TEMPLATE==='template-1' || _TEMPLATE==='template-2') {
            var yData = function() {
                var data = [];
                for (var i = 0; i < 28; i++) {
                    if (i%2===0) {
                        data.push('');    
                    }else {
                        data.push(i + getWord('number3'));
                    }
                }
                return data;
            }();
        }
        if (_TEMPLATE==='template-3') {
            var yData = function() {
                var data = [];
                for (var i = 0; i < 28; i++) {
                    data.push(i + getWord('number3'));
                }
                return data;
            }();   
        }

        return {
            trendsOption1: {
                grid: {
                    left: '12%',
                    right: '2%',
                    top: '5%'
                },
                title: {
                    text: ''
                },
                legend: {
                    data: ['line']
                },
                calculable: true,
                xAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: '#d9d9d9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#d9d9d9',
                            width: .5,
                            type: 'solid'
                        }
                    },
                    axisLabel: {
                        interval: 1,
                        rotate: 45,
                        color: '#999999',
                        fontSize: 12
                    },
                    data: [],
                }],
                yAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: '#d9d9d9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#d9d9d9',
                            width: .5,
                            type: 'solid'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        color: '#999999',
                        fontSize: 12,
                        showMinLabel:false,
                        showMaxLabel:true
                    },
                    data: yData,
                }],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        textStyle: {
                            color: "#fff"
                        }
                    },
                },
                series: [{
                    name: "",
                    type: "line",
                    stack: "",
                    symbolSize: 8,
                    symbol: 'emptyCircle',
                    itemStyle: {
                        normal: {
                            color: "#3985E1",
                            barBorderRadius: 0,
                            label: {
                                show: true,
                                position: "top",
                                fontSize: '10',
                                color: '#333333',
                                formatter: function(p) {
                                    return p.value > 0 ? (p.value) : '';
                                }
                            }
                        }
                    },
                    lineStyle: {
                        normal: {
                            width: 1,
                            color: "#3985E1"
                        }
                    },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(57, 133, 225, .3)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(255,255,255,.3)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    },
                    data: []
                }],
                animationDuration: 2000
            },
            trendsOption2: {
                grid: {
                    left: '12%',
                    right: '2%',
                    top: '5%'
                },
                title: {
                    text: ''
                },
                legend: {
                    data: ['line']
                },
                calculable: true,
                xAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: '#d9d9d9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#d9d9d9',
                            width: .5,
                            type: 'solid'
                        }
                    },
                    axisLabel: {
                        interval: 1,
                        rotate: 45,
                        color: '#999999',
                        fontSize: 12
                    },
                    data: [],
                }],
                yAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: '#d9d9d9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#d9d9d9',
                            width: .5,
                            type: 'solid'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        color: '#999999',
                        fontSize: 12,
                        showMinLabel:false,
                        showMaxLabel:true
                    },
                    data: yData,
                }],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        textStyle: {
                            color: "#fff"
                        }
                    },
                },
                series: [{
                    name: "",
                    type: "line",
                    stack: "",
                    symbolSize: 8,
                    symbol: 'emptyCircle',
                    itemStyle: {
                        normal: {
                            color: "#3985E1",
                            barBorderRadius: 0,
                            label: {
                                show: true,
                                position: "top",
                                fontSize: '10',
                                color: '#333333',
                                formatter: function(p) {
                                    return p.value > 0 ? (p.value) : '';
                                }
                            }
                        }
                    },
                    lineStyle: {
                        normal: {
                            width: 1,
                            color: "#3985E1"
                        }
                    },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(57, 133, 225, .3)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(255,255,255,.3)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    },
                    data: []
                }],
                animationDuration: 2000
            },
            trendsOption3: {
                grid: {
                    left: '12%',
                    right: '2%',
                    top: '5%',
                    bottom: '25%'
                },
                title: {
                    text: ''
                },
                legend: {
                    data: ['line']
                },
                calculable: true,
                xAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: '#d9d9d9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#d9d9d9',
                            width: .5,
                            type: 'solid'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        rotate: 0,
                        color: '#EC2829',
                        fontSize: 14,
                        formatter:function(val){
                            return val.split("").join("\n");
                        }
                    },
                    data: [],
                }],
                yAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: '#d9d9d9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#d9d9d9',
                            width: .5,
                            type: 'solid'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        color: '#999999',
                        fontSize: 10,
                        showMinLabel:true,
                        showMaxLabel:true
                    },
                    data: yData,
                }],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        textStyle: {
                            color: "#fff"
                        }
                    },
                },
                series: [{
                    name: "",
                    type: "line",
                    stack: "",
                    symbolSize: 8,
                    symbol: 'emptyCircle',
                    itemStyle: {
                        normal: {
                            color: "#EC2829",
                            barBorderRadius: 0,
                            label: {
                                show: false,
                                position: "top",
                                fontSize: '10',
                                color: '#333333',
                                formatter: function(p) {
                                    return p.value > 0 ? (p.value) : '';
                                }
                            }
                        }
                    },
                    lineStyle: {
                        normal: {
                            width: 1,
                            color: "#EC2829"
                        }
                    },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(236,40,41, .3)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(255,255,255,.3)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    },
                    data: []
                }],
                animationDuration: 2000
            },
            header:{
                mainTitle: getWord('trend5'),
                headerMenu,
            },
            analysisData: {
                history: null,
                today: null,
                drawings: null,
            },
        }
    },
    props: [

    ],
    methods: {

    },
    mounted() {
        var that = this;
        data_analysis(JSON.parse(localStorage.getItem('game')).type).then(result => {
            const data = result.data.result;
            data.issues.length=15;
            data.issues.forEach(item=>{
                that['trendsOption'+_TEMPLATE.split('-')[1]].xAxis[0].data.push(item.issue+getWord('period'))
                that['trendsOption'+_TEMPLATE.split('-')[1]].series[0].data.push(item.sum)
            })
            
        })
    },
    computed: {

    },
    watch: {

    },
    inject: [

    ],
    updated() {
        // this.trendsOption.series[0].data[16] = 15;
        // this.$refs.lineChart.chart.setOption(this.trendsOption, true)
    }
};
</script>
<template>
    <transition name="slide">       
    <div class="trends-to-chart" :class="_TEMPLATE">
        <template v-if="_TEMPLATE!=='template-2'">
            <Header
                :mainTitle="header.mainTitle"
                :headerMenu="header.headerMenu"
            ></Header>
        </template>        
        <template v-if="_TEMPLATE==='template-1'">
            <h3>{{ getWord('trend4') }}</h3>
            <v-chart ref="lineChart" class="echarts" :options="trendsOption1" :auto-resize="true" />
        </template>
        <template v-if="_TEMPLATE==='template-2'">
            <h3>{{ getWord('trend4') }}</h3>
            <v-chart ref="lineChart" class="echarts" :options="trendsOption2" :auto-resize="true" />
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <v-chart ref="lineChart" class="echarts" :options="trendsOption3" :auto-resize="true" />
        </template>
    </div>
    </transition>   
</template>
<style lang='scss' scoped>
.slide-enter-active {
    transition: all .3s ease;
}

.slide-leave-active {
    transition: all .3s ease;
}

.slide-enter {
    transform: translateX(100%);
}
.slide-enter-to {
    transform: translateX(0%);
}

.slide-leave {
    transform: translateX(0%);
}
.slide-leave-to {
    transform: translateX(-100%);
}

.trends-to-chart {
    overflow: auto;

    &.template-2 {

        h3 {
            font-size: .36rem;
            font-weight:bold;
            color: #252525;

            &:before {
                content:none;
            }
        }
    }

    h3 {
        padding: .25rem;
        font-size: .32rem;
        font-weight: 500;
        color: #333333;

        &:before {
            content: '';
            width: 3px;
            background-color: #0F417D;
            height: .32rem;
            display: inline-block;
            vertical-align: middle;
            margin-right: .125rem;
            border-radius: 1rem;
        }
    }

    .echarts {
        width: 100vw;
        height: 60vh;
    }
}
</style>